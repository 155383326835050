.paper-card-box {
  border-radius: 10px;
  /* border: solid 1px rgb(226, 226, 226); */
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 2px 5px rgb(177, 177, 177);
}
.flex {
  display: flex;
}

.center {
  align-items: center;
}

.papercard-header {
  padding: 10px;
  background-color: rgb(223, 223, 223);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.paper-footer {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-top: solid 1px rgb(179, 179, 179);
}

.input-width{
    width: 100%;
    height: 10px;
    margin-top: 5px;
    background-color: rgb(255, 153, 0);
}

.blur{
     background-color: white;
     backdrop-filter: blur(10);
}

.current-text{
    color: rgb(4, 118, 248);
    font-weight: 600;
    font-size: 16px;
}
.over-view{
    color: red;
    font-weight: 600;
    font-size: 16px;
}
.paper-title{
    font-weight: 600;
    margin-right: 10px;
}
.addnew-paper{
    margin-left: 10px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.blur-text {
    filter: blur(5px); /* Adjust the value to increase or decrease the blur */
    color: transparent; /* Makes the original text transparent */
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Adds shadow for the blurred effect */
}
