

 
/* .loader {
    display: flex;
  }
  
  .loader .dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #212121;
    background: #16b0c1;
    animation: jump 0.8s ease-in-out infinite alternate;
  }
  
  @keyframes jump {
    100% {
      background: #661e92;
      transform: translateY(-3rem) scale(1.9);
    }
  }
  
  .loader .dot:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .loader .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .loader .dot:nth-child(3) {
    animation-delay: 0.3s;
  }
  
  .loader .dot:nth-child(4) {
    animation-delay: 0.4s;
  }
  
  .loader .dot:nth-child(5) {
    animation-delay: 0.5s;
  } */

  
  .loadin-main-box {
    position: fixed;
    bottom: 0;
    z-index: 100000000000000 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.589
    ); 
    overflow: hidden;
  }


 
.loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: white;
  }
  
  .loader:before,
  .loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  
  .loader:after {
    color: #3F51B5d4;
    transform: rotateY(70deg);
    animation-delay: .3s;
  }
  
  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }
  
  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  
  @keyframes spin {
    0%,
    100% {
      box-shadow: .2em 0px 0 0px currentcolor;
    }
  
    12% {
      box-shadow: .2em .2em 0 0 currentcolor;
    }
  
    25% {
      box-shadow: 0 .2em 0 0px currentcolor;
    }
  
    37% {
      box-shadow: -.2em .2em 0 0 currentcolor;
    }
  
    50% {
      box-shadow: -.2em 0 0 0 currentcolor;
    }
  
    62% {
      box-shadow: -.2em -.2em 0 0 currentcolor;
    }
  
    75% {
      box-shadow: 0px -.2em 0 0 currentcolor;
    }
  
    87% {
      box-shadow: .2em -.2em 0 0 currentcolor;
    }
  }
     

