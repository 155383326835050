.tabel-target {
    width: 100%;
    
  }
  .tabel-target table {
    width: 100%;
    border-collapse: collapse;
    margin: 5px 0;
    font-size: 16px;
    text-align: left;
  }
   
  .tabel-target th,
  .tabel-target td {
    padding: 8px 15px;
    border-bottom: 1px solid #ddd;
    color:#3F51B5d4;
    border-left: none;
    border-right: none;
  }
   
  .tabel-target thead {
    background-color: #f4f4f4;
  }
   
  .tabel-target th {
    font-weight:400;
    color: #333;
    border-left: none;
    border-right: none;
  }
   
  .tabel-target tr:nth-child(even) {
    background-color: #f9f9f9;
  }
   
  .tabel-target tr:hover {
    background-color: #f1f1f1;
  }

  .productdeatils-insidebox {
    border: solid 1px;
    padding: 10px;
    border-radius: 10px;
    margin: 0px 5px;
  }
  .inside-head {
    background-color: rgb(17, 179, 243);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600 !important;
  }
  
  .mainproduct-box {
    border: solid 1px black;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .quation-add {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 18px;
    border-radius: 50%;
    cursor: not-allowed;
    border: none;
    background-color: rgb(17, 179, 243);
    color: black;
    font-weight: 600;
    font-size: 18px;
  }
  
  .actionbutton {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    cursor: pointer;
    padding-right: 10px;
  }
  .iconsize {
    margin-top: 18px;
    font-size: 20px;
    margin-right: 10px;
  }
  .onclick{
    padding-left: 20px;
  }
  